/**
 * @generated SignedSource<<6ac202bf979512b9ca09ad2490f5ed35>>
 * @relayHash d1b6baa27dea087146f92151cc989835
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/505.0.0-2025-04-09T14:59:42.267Z/appDownloadModalConfigQuery

import { ConcreteRequest } from 'relay-runtime';
export type appDownloadModalConfigQuery$variables = {
  countryCode?: string | null;
};
export type appDownloadModalConfigQuery$data = {
  readonly viewer: {
    readonly appDownloadDisplayInfo: {
      readonly isAppDownloadApplicable: boolean | null;
    } | null;
  };
};
export type appDownloadModalConfigQuery = {
  response: appDownloadModalConfigQuery$data;
  variables: appDownloadModalConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "countryCode"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AppDownloadDisplayInfo",
  "kind": "LinkedField",
  "name": "appDownloadDisplayInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "countryCode",
          "variableName": "countryCode"
        }
      ],
      "kind": "ScalarField",
      "name": "isAppDownloadApplicable",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "appDownloadModalConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "appDownloadModalConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/505.0.0-2025-04-09T14:59:42.267Z/appDownloadModalConfigQuery",
    "metadata": {},
    "name": "appDownloadModalConfigQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "73897345eee401c333d2d395634cff8a";

export default node;
